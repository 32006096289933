import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  Link,
  Navigate,
  Route,
  Routes,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import useStore from '@/stores/useStore'

import { auth } from '@/services/Firebase'
import { ROUTES } from '@/const/routes'

import PLoginWithEmailLinkConfirmation from '@/components/pages/PLoginWithEmailLinkConfirmation'
import PLogin from '@/components/pages/v2/PLogin/PLogin.v2'
import { POnboarding } from '@/components/pages/v2/POnboarding/POnboarding'

import Dashboard from '@/components/pages/Dashboard.jsx'
import PRojectDetails from '@/components/pages/PProjectDetails.jsx'
import POrganizationDetails from '@/components/pages/POrganizationDetails'
import PTeamDetails from '@/components/pages/PTeamDetails'
import PUsers from '@/components/pages/PUsers.tsx'
import PManageVMs from '@/components/pages/PManageVMs'
import PIterationFeedback from './components/pages/PIterationFeedback'
import { Intercom } from '@intercom/messenger-js-sdk'

import {
  ANALYTIC_EVENTS,
  analyticsIdentify,
  analyticsIdentifyReset,
  analyticsIsOptedInCapturing,
  analyticsOpInCapturing,
  analyticsOptOutCapturing,
  analyticsTrackEvent,
} from './services/Analytics'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import {
  Building2,
  LayoutDashboard,
  Settings,
  User,
  Users,
  Zap,
  Plus,
  FolderOpen,
  PanelLeftClose,
  PanelRightClose,
  CircleUser,
} from 'lucide-react'
import MCreateProjectDialog from '@/components/molecules/project-details/CreateProject/MCreateProjectDialog.tsx'
import { POnboardingBuilding } from './components/pages/v2/POnboardingBuilding/POnboardingBuilding'
import { OnboardingProvider } from '@/components/pages/v2/POnboarding/OnboardingProvider.tsx'
import PHome from '@/components/pages/PHome.jsx'
import { signOut } from '@/services/Authentication.js'
import Logo from '@/assets/svg-components/Logo.jsx'
import LogoText from '@/assets/svg-components/LogoText.tsx'
import PRobotLogin from './components/pages/v2/PLogin/PRobotLogin.tsx'
import MDesktopRestriction from './components/molecules/MDesktopRestriction.tsx'

function LoginRouter() {
  return (
    <>
      <Routes>
        <Route path={ROUTES.ROOT} element={<PLogin />} />
        <Route path="__robot-login" element={<PRobotLogin />} />
        <Route
          exact
          path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION}
          element={<PLoginWithEmailLinkConfirmation />}
        />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <ScrollRestoration />
      <MDesktopRestriction />
    </>
  )
}

function OnboardingRouter() {
  return (
    <>
      <OnboardingProvider>
        <Routes>
          <Route path={ROUTES.ONBOARDING_BUILDING} element={<POnboardingBuilding />} />
          <Route path={ROUTES.ONBOARDING} element={<POnboarding />} />
          <Route path="*" element={<Navigate replace to={ROUTES.ONBOARDING} />} />
        </Routes>
      </OnboardingProvider>
      <MDesktopRestriction />

      <ScrollRestoration />
    </>
  )
}

function MMainAnalytics() {
  const [authUser] = useAuthState(auth)
  const appOpenEventSent = useRef(false)
  const organizations = useStore(state => state.organizations)
  const user = useStore(state => state.user)
  const userRoles = useStore(state => state.userRoles)
  const location = useLocation()

  useEffect(() => {
    if (authUser?.uid && user && organizations.length > 0 && userRoles) {
      const isExternal = user?.isExternal ? true : false

      // if user is external, we need to track the user
      // if user is internal, we need to opt out of tracking
      if (isExternal) {
        if (!analyticsIsOptedInCapturing()) {
          analyticsOpInCapturing()
        }

        const orgIds = organizations.map(org => org.id)
        analyticsIdentify(authUser?.uid, {
          name: `${user?.firstName} ${user?.lastName}`,
          userId: authUser?.uid,
          isExternal,
          organizations: orgIds,
        })
        if (!appOpenEventSent.current) {
          analyticsTrackEvent(ANALYTIC_EVENTS.APP_OPEN)
          appOpenEventSent.current = true
        }
      } else {
        if (analyticsIsOptedInCapturing()) {
          analyticsOptOutCapturing()
        }
      }
    }
  }, [user, organizations, userRoles, authUser?.uid])

  useEffect(() => {
    analyticsTrackEvent('$pageview')
  }, [location])

  return null
}

function App() {
  const [authUser, isAuthUserLoading] = useAuthState(auth)
  const loadInitialData = useStore(state => state.loadInitialData)
  const user = useStore(state => state.user)
  const isAfterInitialLoad = useStore(state => state.isAfterInitialLoad)
  const navigate = useNavigate()

  const isUserLoggedOut = useMemo(() => {
    return Boolean(!authUser && !isAuthUserLoading)
  }, [authUser, isAuthUserLoading])

  const isOnboarding = user?.email && user.isOnboarding

  // Load initial data when auth is ready and user is logged in
  // Unsubscribe from all listeners when component is unmounted
  useEffect(() => {
    if (authUser?.uid) {
      loadInitialData(authUser.uid)
    }
    return () => {
      useStore.getState().unsubscribeFromAllListeners()
    }
  }, [authUser?.uid, loadInitialData])

  useLayoutEffect(() => {
    Intercom({
      app_id: 'yiwl0c30',
      user_id: user.uid,
      name: user?.firstName ? `${user.firstName} ${user.lastName}` : undefined,
      email: user?.email,
      created_at: user?.createdAt?.seconds,
    })
  }, [authUser?.uid])

  const isSuperAdminFn = useStore(state => state.isSuperAdmin)
  const userRoles = useStore(state => state.userRoles)

  const isSuperAdmin = useMemo(() => {
    if (userRoles) {
      return isSuperAdminFn()
    } else {
      return false
    }
  }, [isSuperAdminFn, userRoles])

  if (isAuthUserLoading) {
    return null
  }

  if (isUserLoggedOut) {
    return <LoginRouter />
  }

  if (!user?.email) {
    return null
  }

  if (isOnboarding) {
    return <OnboardingRouter />
  }

  const redirectToProjectUrl = user.projectToRedirectAfterOnboarding
    ? `/projects/${user.projectToRedirectAfterOnboarding}`
    : ROUTES.HOME

  if (!isSuperAdmin) {
    return (
      <Layout>
        <Routes>
          <Route
            path={ROUTES.ONBOARDING_BUILDING}
            element={<Navigate replace to={redirectToProjectUrl} />}
          />
          <Route
            path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION}
            element={<Navigate replace to="/" />}
          />
          {/*<Route path={ROUTES.HOME} exact element={<PHome />} />*/}
          <Route path={ROUTES.PROJECTS} exact element={<PHome />} />
          <Route path={ROUTES.PROJECT_DETAILS} element={<PRojectDetails />} />
          <Route path="*" element={<Navigate replace to={ROUTES.PROJECTS} />} />
        </Routes>
        {/*<ScrollRestoration />*/}
        <MMainAnalytics />
      </Layout>
    )
  }

  return (
    <Layout>
      <Routes>
        <Route
          path={ROUTES.ONBOARDING_BUILDING}
          element={<Navigate replace to={redirectToProjectUrl} />}
        />
        <Route path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION} element={<Navigate replace to="/" />} />
        {/*<Route path={ROUTES.HOME} exact element={<PHome />} />*/}
        <Route path={ROUTES.DASHBOARD} exact element={<Dashboard />} />
        <Route path={ROUTES.PROJECTS} exact element={<PHome />} />
        <Route path={ROUTES.PROJECT_DETAILS} element={<PRojectDetails />} />
        <Route path={ROUTES.ORGANIZATION_DETAILS} element={<POrganizationDetails />} />
        <Route path={ROUTES.TEAM_DETAILS} element={<PTeamDetails />} />
        <Route path={ROUTES.MANAGE_VMS} element={<PManageVMs />} />
        <Route path={ROUTES.ITERATION_FEEDBACK} element={<PIterationFeedback />} />
        <Route path={ROUTES.USERS} element={<PUsers />} />
        <Route path="*" element={<Navigate replace to={ROUTES.PROJECTS} />} />
      </Routes>
      {/*<ScrollRestoration />*/}
      <MMainAnalytics />
    </Layout>
  )
}

function Sidebar({ isExpanded, setIsExpanded }) {
  const location = useLocation()

  const [isCreateProjectDialogOpen, setIsCreateProjectDialogOpen] = useState(false)
  const isSuperAdminFn = useStore(state => state.isSuperAdmin)
  const userRoles = useStore(state => state.userRoles)

  const handleSignOut = useCallback(async () => {
    await signOut()
    analyticsTrackEvent(ANALYTIC_EVENTS.AUTH_LOGOUT)
    analyticsIdentifyReset()
  }, [])

  const isSuperAdmin = useMemo(() => {
    if (userRoles) {
      return isSuperAdminFn()
    } else {
      return false
    }
  }, [isSuperAdminFn, userRoles])

  const toggleSidebar = () => setIsExpanded(!isExpanded)

  let menuItems
  if (isSuperAdmin) {
    menuItems = [
      { icon: LayoutDashboard, label: 'Dashboard', path: ROUTES.DASHBOARD },
      { icon: FolderOpen, label: 'Projects', path: ROUTES.PROJECTS },
      { icon: Users, label: 'Team', path: ROUTES.TEAM_DETAILS },
      { icon: Building2, label: 'Organization', path: ROUTES.ORGANIZATION_DETAILS },
      { icon: Zap, label: 'Manage VMs', path: ROUTES.MANAGE_VMS },
      { icon: Settings, label: 'Iteration Feedback', path: ROUTES.ITERATION_FEEDBACK },
      { icon: Users, label: 'Users', path: ROUTES.USERS },
    ]
  } else {
    menuItems = [{ icon: FolderOpen, label: 'Projects', path: ROUTES.PROJECTS }]
  }

  const isCurrentPath = path => {
    return location.pathname === path
  }

  return (
    <aside
      className={cn(
        "flex flex-col font-['Inter'] text-sm font-medium leading-tight text-stone-500   ",
        isExpanded ? 'w-64 p-4' : 'w-16 p-4'
      )}
    >
      <div className="mb-6 flex items-center justify-center">
        {isExpanded ? (
          <div className="flex h-6 shrink grow basis-0 items-center justify-between p-2">
            <LogoText />
            <Logo className="size-6" />
          </div>
        ) : (
          <div className="flex size-6 items-center justify-center rounded-full ">
            <Logo className="size-6" />
          </div>
        )}
      </div>

      <button
        className=" mb-3 flex h-10 cursor-pointer items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2"
        onClick={() => setIsCreateProjectDialogOpen(true)}
        data-testid="open-create-project-btn"
      >
        <div className=" flex size-8 items-center justify-center ">
          <Plus className="text-stone-50" />
        </div>
        {isExpanded && (
          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-50">
            New Project
          </div>
        )}
      </button>

      <nav className="flex-grow">
        <ul className="space-y-2">
          {menuItems.map((item, index) => (
            <li key={index}>
              <Button
                variant="ghost"
                className={cn(
                  'w-full justify-center hover:bg-stone-200 hover:text-stone-900',
                  isExpanded ? 'justify-start px-4' : 'px-2',
                  isCurrentPath(item.path) && 'relative bg-stone-200 hover:bg-stone-300'
                )}
                asChild
              >
                <Link to={item.path}>
                  <item.icon
                    className={cn(
                      'size-5 flex-shrink-0',
                      isCurrentPath(item.path) && 'text-orange-500'
                    )}
                  />
                  {isExpanded && (
                    <span className={cn('ml-2', isCurrentPath(item.path) && 'text-stone-900')}>
                      {item.label}
                    </span>
                  )}
                </Link>
              </Button>
            </li>
          ))}
        </ul>
      </nav>

      <div className="mt-auto space-y-2 ">
        <Button
          variant="ghost"
          onClick={handleSignOut}
          className={cn('w-full justify-center ', isExpanded ? 'justify-start px-4' : 'px-2')}
        >
          <CircleUser className="size-5 " />
          {isExpanded && <span className="ml-2">Sign out</span>}
        </Button>
        <div className="flex h-4 flex-col items-start justify-start gap-2.5 self-stretch px-4 py-2">
          <div className="h-[0px] self-stretch border border-stone-200"></div>
        </div>
        <Button
          variant="ghost"
          onClick={toggleSidebar}
          className={cn('w-full ', isExpanded ? 'justify-start px-4' : 'justify-center px-2')}
        >
          {isExpanded ? (
            <>
              <PanelLeftClose />
              <span className="ml-2">Collapse</span>
            </>
          ) : (
            <PanelRightClose />
          )}
        </Button>
      </div>
      {isCreateProjectDialogOpen && (
        <MCreateProjectDialog
          key={'-create-project-dialog'}
          isOpen={isCreateProjectDialogOpen}
          onClose={() => {
            setIsCreateProjectDialogOpen(false)
          }}
          onProjectCreated={data => {}}
          formDefaults={{ project: {}, env: {}, prompt: {} }}
        />
      )}
    </aside>
  )
}

function Layout({ children }) {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div className="flex h-screen overflow-hidden bg-stone-100">
      <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <main className="flex flex-1 justify-center overflow-y-auto bg-stone-100">{children}</main>

      <MDesktopRestriction />
    </div>
  )
}

export default App
