import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import { Goal } from 'lucide-react'

function ReflectorItem({ title, items, descriptionFieldName = 'description' }) {
  return (
    <div className="rounded-md border border-border px-4 py-3 text-muted-foreground">
      <div className="font-dm-mono">{title}:</div>
      <ul className="list-disc space-y-1 px-6 ">
        {items.map((item, index) => (
          <li key={`reflection-${item?.id || index}`}>
            {item[descriptionFieldName] || 'No relevant info found'}
          </li>
        ))}
      </ul>
    </div>
  )
}

function ReflectorContent({ plan, advices, reflections, stepBoundary }) {
  const descriptionContent = []

  if (reflections && Array.isArray(reflections) && reflections.length > 0) {
    descriptionContent.push(<ReflectorItem title="Reflections" items={reflections} />)
  }

  if (stepBoundary) {
    descriptionContent.push(
      <div
        key="step-boundry"
        className="space-y-1 whitespace-pre-line rounded-md border border-border border-stone-200 px-4 py-3 text-muted-foreground"
      >
        <div className="font-dm-mono">Step Boundary:</div>
        <div className="">{stepBoundary}</div>
      </div>
    )
  }

  if (Array.isArray(plan) && plan.length > 0) {
    descriptionContent.push(<ReflectorItem title="Plan" items={plan} />)
  }

  if (advices && Array.isArray(advices) && advices.length > 0) {
    descriptionContent.push(
      <ReflectorItem title="Advices" items={advices} descriptionFieldName="relevant_info" />
    )
  }

  return <AActionContentDescription content={descriptionContent} />
}

export default function ReflectorAction({ action, isLast = false }) {
  const reflections = action?.args?.reflection || action?.args?.Reflection
  const reflection = action?.args?.reflection_summary || 'No reflection summary'
  const advices = action?.args?.advices || []
  const plan = action?.args?.plan || []
  const stepBoundary = action?.args?.step_boundary

  const descriptionContent = [
    <div key="reflection" className="whitespace-pre-line">
      {reflection}
    </div>,
  ]

  return (
    <GenericAction
      title={action?.args?.comment ?? 'Reflector'}
      action={action}
      actionIcon={Goal}
      isLast={isLast}
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={descriptionContent} />
        <ReflectorContent
          reflections={reflections}
          advices={advices}
          plan={plan}
          stepBoundary={stepBoundary}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ReflectorAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
