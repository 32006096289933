import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { Sparkle } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function LlmRequestAction({ action, isLast = false }) {
  const actionDebugIndexedId = `${action?.iterationId} ${action?.elementIndex} ${action?.stepIndex} ${action.index}`

  let content = 'LLM Request'
  let detailsContent = `#### --- DEBUG scripts: \n\n`
  detailsContent += `Slingshot:\n\`\`\`shell\n./playgrounds/slingshot ${actionDebugIndexedId}\n\`\`\`\n\n`
  detailsContent += `Request Lab (download):\n\`\`\`shell\nreqlab download ${actionDebugIndexedId}\n\`\`\`\n\n`
  detailsContent += `Request Lab (critique and re-run request):\n\`\`\`shell\nreqlab critique-and-run ${actionDebugIndexedId}\n\`\`\`\n\n`

  const messages = action?.args?.messages
  const tools = action?.args?.tools
  const description = tools?.[0]?.function?.description
  if (description) {
    content = description
  } else if (messages) {
    // do this only is there's no description
    // don't move details content creation to this logical block
    content = ''
    messages.forEach(message => {
      content += `[${message.role.toUpperCase()}] ${message.label}\n`
    })
  }

  if (messages) {
    detailsContent += `## ----------------------- MESSAGES-----------------------\n\n`
    messages.forEach(message => {
      const messageContent =
        typeof message?.content === 'string'
          ? message?.content
          : JSON.stringify(message?.content, null, 2).replace(/\\n/g, '\n')
      detailsContent += `# --- ${message?.role?.toUpperCase()}: ${message?.label}\n${messageContent}\n\n`
    })
    detailsContent += `## -----------------------/MESSAGES-----------------------\n\n`
  }

  if (tools) {
    detailsContent += `## ----------------------- TOOLS-----------------------\n\n`
    tools.forEach(tool => {
      detailsContent += `### ${tool?.function?.name}\n${tool?.function?.description}\n`
      const parameters = tool?.function?.parameters
      if (parameters) {
        detailsContent += `Parameters:\n`
        detailsContent += '```json\n' + JSON.stringify(parameters, null, 2) + '\n```\n'
      }
    })
    detailsContent += `\n## -----------------------/TOOLS-----------------------\n\n`
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={Sparkle}
      isLast={isLast}
      title="LLM Request"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={content} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

LlmRequestAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
