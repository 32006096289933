import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { CircleCheck } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import { insertNewlinesIfNeeded } from '@/components/actions/utils.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import { getObjectValue } from '@/lib/action-utils.js'

export default function StepDoneAction({ action, isLast = false }) {
  let report = action.args?.report
  let artifacts = action.args?.artifacts
  let artifactsSplit = ''
  if (Array.isArray(artifacts)) {
    artifactsSplit = artifacts
  } else if (typeof artifacts === 'string') {
    artifactsSplit = artifacts?.split(',')
  } else if (typeof artifacts === 'object' && artifacts !== null) {
    artifactsSplit = Object.entries(artifacts).map(([key, value]) => `${key}: ${value}`)
  }
  const content = [report]
  if (artifactsSplit) {
    artifactsSplit.forEach(artItem => {
      content.push(artItem)
    })
  }

  if (Array.isArray(artifacts)) {
    artifacts = artifacts
      .map((art, index) => {
        if (typeof art === 'object') {
          const { value, type } = getObjectValue(art)
          const artString = JSON.stringify(value, null, 2)
          return `* ${index + 1}:\n\`\`\`${type}\n${artString}\n\`\`\``
        } else {
          return `* ${index + 1}:\n${art}`
        }
      })
      .join('\n\n')
  } else if (typeof artifacts === 'object') {
    artifacts = JSON.stringify(artifacts, null, 2)
  }

  const hasContent = Boolean(content?.length) || action.name

  return (
    <GenericAction
      action={action}
      detailsContent={`## REPORT:\n${insertNewlinesIfNeeded(report)}\n\n## Artifacts:\n${insertNewlinesIfNeeded(artifacts)}\n`}
      actionIcon={CircleCheck}
      isLast={isLast}
      title="Summarize & commit"
    >
      {hasContent && (
        <AActionContentGenericLayout>
          <AActionContentDescription content={content || action.name} />
        </AActionContentGenericLayout>
      )}
    </GenericAction>
  )
}

StepDoneAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
