import { useMemo } from 'react'

import { contentShape } from '@/components/propTypes.js'
import { getObjectValue } from '@/lib/action-utils.js'

export default function AActionContentDescription({ content }) {
  let contentTexts = useMemo(() => {
    const safeContent = content || ''
    let arrToReturn = []
    if (typeof safeContent === 'string') {
      arrToReturn = [safeContent]
    } else if (isReactElement(safeContent)) {
      arrToReturn = safeContent
    } else if (Array.isArray(safeContent)) {
      arrToReturn = safeContent.map((content, index) => {
        if (isReactElement(content)) {
          return content
        } else if (typeof content === 'object') {
          const { value, type } = getObjectValue(content)
          const jsonContent = JSON.stringify(value, null, 2)
          return (
            <pre
              key={index}
              className="mt-4 overflow-x-auto rounded-md border border-gray-300 bg-gray-100 p-4"
            >
              <div>{type}</div>
              <code className="whitespace-pre-wrap break-words text-sm text-gray-800">
                {jsonContent}
              </code>
            </pre>
          )
        } else {
          return content
        }
      })
    }
    return arrToReturn
  }, [content])
  return (
    <div className="space-y-4">
      {contentTexts.map((content, index) => {
        return (
          <div className="whitespace-pre-wrap" key={index}>
            {content}
          </div>
        )
      })}
    </div>
  )
}

AActionContentDescription.propTypes = { content: contentShape }

function isReactElement(obj) {
  return typeof obj === 'object' && obj !== null && obj.$$typeof === Symbol.for('react.element')
}
